<template>
  <Card>
    <template #title>
      Værktøjer de har brug for til at udføre deres job
    </template>
    <template #content>
      <div class="cb_list">
        <div v-for="tool of listTools" :key="tool.id" class="p-field-checkbox">
          <Checkbox :id="tool.id" name="tools" :value="tool.val" v-model="personaTools" />
          <label :for="tool.id">{{ tool.val }}</label>
        </div>
      </div>
      <div>
        <span class="p-float-label">
          <InputText id="additionalTool" type="text" @keydown="addAdditionalTool" v-model="additionalTool"/>
          <label for="additionalTool">Du kan tilføje en her og trykke på enter.</label>
        </span>
      </div>
    </template>
  </Card>
</template>

<script>
import {computed, ref} from "vue";
import store from "@/store/store";

export default {
  name: "ToolsNeedDoJob",
  setup(){
    const listTools = computed( () => { return store.state.listTools})

    const personaTools = computed({
      get: () => store.state.personaTools,
      set: val => {
        store.dispatch(('setPersonaTools'), {tools: val})
      }
    })

    let additionalTool = ref("")

    function addAdditionalTool(e) {
      if(e.keyCode === 13) {
        const toolId = additionalTool.value.replace(' ', '-')
        const tool = {id: toolId, val: additionalTool.value}

        let toolFound = listTools.value.find( tool => tool.id === toolId )
        if(!toolFound) {
          store.dispatch(('updateListTools'), {tool: tool})
          store.dispatch(('updatePersonaTools'), {tool: tool.val})
          additionalTool.value = "";
        }
      }
    }

    return{
      listTools,
      personaTools,
      additionalTool,
      addAdditionalTool
    }
  }
}
</script>

<style scoped>
.p-field-checkbox {
  flex: 0 0 100%;
}

</style>