<template>
  <Dialog header="Indtast dine kontaktinformationer nedenunder for at downloade din Persona" v-model:visible="displayModal" :style="{width: '50vw'}" :modal="true">
    <span class="p-float-label">
        <InputText id="firstName" type="text" v-model="firstName" :class="{errorFirstName: 'p-invalid'}"/>
        <label for="firstName">Fornavn *</label>
    </span>
    <small id="firstName-help" class="p-error" v-show="errorFirstName">Indtast venligst fornavn.</small>
    <span class="p-float-label">
        <InputText id="lastName" type="text" v-model="lastName" :class="{errorLastName: 'p-invalid'}"/>
        <label for="lastName">Efternavn *</label>
    </span>
    <small id="lastName-help" class="p-error" v-show="errorLastName">Indtast venligst efternavn</small>
    <span class="p-float-label">
        <InputText id="email" type="text" v-model="email" :class="{errorEmail: 'p-invalid'}"/>
        <label for="email">E-mail *</label>
    </span>
    <small id="email-help" class="p-error" v-show="errorEmail">Indtast en gyldig email.</small>
    <span class="p-float-label">
        <InputText id="phone" type="text" v-model="phone" :class="{errorPhone: 'p-invalid'}"/>
        <label for="phone">Telefonnummer *</label>
    </span>
    <small id="phone-help" class="p-error" v-show="errorPhone">Indtast gydlig telefonnummer</small>
    <div class="form_dropdown">
      <div class="form_label">
        <label>Hvad beskriver bedst din jobfunktion? *</label>
      </div>
      <Dropdown id="job-function" v-model="selectedJobFunction" :options="jobFunctions" optionLabel="name" optionValue="name" placeholder="Vælg venligst jobfunktion" />
      <small id="job-function-help" class="p-error" v-show="errorJobFunction">Vælg venligst jobfunktion</small>
    </div>
    <div>
      <p>
        Subscription Circle har brug for dine kontaktoplysninger for at vi kan kontakte dig angående vores
        produkter og tjenester. Du kan afmelde disse meddelelser til enhver tid. For mere information om,
        hvordan man afmelder, om vores praksis, og hvordan vi er engageret i at beskytte og respektere dit
        privatliv, kan du læse vores <a target="_blank" href="https://subscriptioncircle.com/privatlivspolitik">privatlivspolitik</a>.
      </p>
    </div>
    <div>
      <small id="form-help" class="p-error" v-show="errorHubspotForm" v-html="hubspotFormErrorMessage"></small>
    </div>
    <template #footer>
      <Button label="Luk" icon="pi pi-times" @click="closeModal" class="p-button-text"/>
      <Button label="Download" icon="pi pi-check" @click="submitLeadForm" autofocus />
    </template>
  </Dialog>
</template>

<script>
import axios from "axios";
import store from "@/store/store";
import {computed, ref} from "vue";
import jspdf from "jspdf";

export default {
  name: "HubspotLeadForm",
  setup() {
    const displayModal = computed({
      get: () => store.state.displayHubspotForm,
      set: val => {
        store.dispatch(('setDisplayHubspotForm'), {displayModal: val})
      }
    })

    let firstName = ref("")
    let lastName = ref("")
    let company = ref("")
    let email = ref("")
    let phone = ref("")
    let website = ref("")
    let selectedJobFunction = ref("")
    const jobFunctions = [
      {name: 'Andet'},
      {name: 'Grundlægger/Ejer/Partner/Direktør'},
      {name: 'Kommunikationschef'},
      {name: 'Konsulent på et marketing-, reklame- eller kommunikationsbureau'},
      {name: 'Marketing-manager, -koordinator eller lign.'},
      {name: 'Marketingchef eller lign.'},
      {name: 'Salgschef'},
      {name: 'Studerende'},
    ]

    let errorFirstName = ref(false)
    let errorLastName = ref(false)
    let errorCompany = ref(false)
    let errorEmail = ref(false)
    let errorPhone = ref(false)
    let errorWebsite = ref(false)
    let errorJobFunction = ref(false)

    let errorHubspotForm = ref(false)
    let hubspotFormErrorMessage = ref("")

    function closeModal() {
      resetData()
      store.dispatch('setDisplayHubspotForm', {displayModal: false})
    }

    function resetData() {
      firstName.value = "";
      lastName.value = "";
      company.value = "";
      email.value = "";
      phone.value = "";
      website.value = "";
      selectedJobFunction.value = "";

      errorFirstName.value = false;
      errorLastName.value = false;
      errorCompany.value = false;
      errorEmail.value = false;
      errorPhone.value = false;
      errorWebsite.value = false;
      errorJobFunction.value = false;

      errorHubspotForm.value = false
      hubspotFormErrorMessage.value = ""
    }

    function isFormValid(){
      let isValid = true

      if(firstName.value.trim().length < 1){
        errorFirstName.value = true;
        isValid = false
      } else {
        errorFirstName.value = false;
      }
      if(lastName.value.trim().length < 1){
        errorLastName.value = true;
        isValid = false
      } else {
        errorLastName.value = false;
      }
      if(email.value.trim().length < 1){
        errorEmail.value = true;
        isValid = false
      } else {
        errorEmail.value = false;
      }
      if(phone.value.trim().length < 1){
        errorPhone.value = true;
        isValid = false
      } else {
        errorPhone.value = false;
      }
      if(selectedJobFunction.value.trim().length < 1){
        errorJobFunction.value = true;
        isValid = false
      } else {
        errorJobFunction.value = false;
      }

      return isValid
    }

    function createContact(){

      if(isFormValid()) {

        axios({
          method: 'post',
          url: 'https://persona.cphdigital.dk/createContact.php',
          data: {
            company: company.value,
            email: email.value,
            firstname: firstName.value,
            lastname: lastName.value,
            phone: phone.value,
            website: website.value
          }
        }).then((respons) => {

          let status = ""
          if(respons.status) {
            status = respons.status;
          }

          if(status == 'error'){
            //Show error
          } else {
            generatePdf()
            closeModal()
          }

          console.log(respons)
        });
      }
    }

    function showHubspotFormError(formResponse) {

      if(formResponse.status == "error"){
        errorHubspotForm.value = true;
      } else {
        errorHubspotForm.value = false;
      }

      hubspotFormErrorMessage.value = ""
      if(formResponse.errors.length > 0){
        for(let error of formResponse.errors){

          if(error.errorType == "INVALID_EMAIL"){
            errorEmail.value = true
          } else if(error.message.includes("'fields.phone'")){
            errorPhone.value = true
          } else{
            hubspotFormErrorMessage.value += error.message + "<br />"
          }
        }
      }
    }

    function submitLeadForm(){

      if(isFormValid()) {

        axios({
          method: 'post',
          url: '/submitLeadForm.php',
          data: {
            email: email.value,
            firstname: firstName.value,
            lastname: lastName.value,
            phone: phone.value,
            hs_persona: selectedJobFunction.value
          }
        }).then((respons) => {

          let status = ""
          if(respons.data.status) {
            status = respons.data.status
          }

          if(status == 'error'){
            showHubspotFormError(respons.data)
          } else {
            generatePdf()
            closeModal()
          }
          console.log(respons)

        });
      }
    }

    /************************/
    /* Generate Persona PDF */
    /************************/
    const avatarImg = computed( () => {
      return `/gfx/avatars/${store.state.avatar}.png`
    })
    const personaName = computed( () => {
      if (store.state.personaName.length > 0) {
        return store.state.personaName
      } else {
        return "Persona"
      }
    })
    const jobTitle = computed( () => store.state.jobTitle )
    const age = computed( () => store.state.personaAge )
    const levelOfSchool = computed( () => store.state.levelOfSchool )
    const industry = computed( () => {
      if(store.state.industry){
        return store.state.industry
      } else {
        return '';
      }
    })
    const sizeOfOrganization = computed( () => store.state.sizeOfOrganization )
    const socialNetworks = computed( () => store.state.personaSocialNetwork )
    const personaCommunicate = computed( () => store.state.personaCommunicate )
    const personaTools = computed( () => store.state.personaTools )
    const jobResponsibilities = computed( () => store.state.jobResponsibilities )
    const jobMeasured = computed( () => store.state.jobMeasured )
    const whoReportTo = computed( () => store.state.whoReportTo )
    const goalsOrObjectives = computed( () => store.state.goalsOrObjectives )
    const howGainInformationForJob = computed( () => store.state.howGainInformationForJob )
    const personaBiggestChallenges = computed( () => store.state.personaBiggestChallenges )

    async function imgToBase64Async(url) {
      const base64 = new Promise((resolve) => {
        var xhr = new XMLHttpRequest();
        xhr.onload = function() {
          var reader = new FileReader();
          reader.onloadend = function() {
            resolve(reader.result);
          }
          reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
      })

      return await base64
    }

    async function generatePdf() {
      const startLeft = 15;
      const avatarWidth = 30;
      const alignUnderAvatar = avatarWidth / 2 + startLeft
      const infoMaxWidth = avatarWidth + 2 * startLeft
      const additionalInfoStartLeft = infoMaxWidth + 5
      const additionalInfoMaxWidth = 210 - additionalInfoStartLeft - startLeft

      const pdfDoc = new jspdf('p', 'mm', 'a4')

      // Name and title
      pdfDoc.setFontSize(20)
      pdfDoc.text(personaName.value, 105, 20, {
        align: 'center',
      });
      pdfDoc.setFontSize(10)
      pdfDoc.text(jobTitle.value, 105, 26, {
        align: 'center',
      });

      // Add avatar image
      const avatarImageData = await imgToBase64Async(avatarImg.value)
      pdfDoc.addImage(avatarImageData, 'PNG', startLeft, 30, avatarWidth, avatarWidth)

      // Age
      pdfDoc.setFontSize(10)
      pdfDoc.text('Alder', alignUnderAvatar, 66, {
        align: 'center',
      });
      pdfDoc.setFontSize(8)
      pdfDoc.text(age.value, alignUnderAvatar, 72, {
        align: 'center',
        maxWidth: infoMaxWidth
      });

      // Level of Education
      pdfDoc.setFontSize(10)
      pdfDoc.text('Højeste uddannelse', alignUnderAvatar, 88, {
        align: 'center',
      });
      pdfDoc.setFontSize(8)
      pdfDoc.text(levelOfSchool.value, alignUnderAvatar, 94, {
        align: 'center',
        maxWidth: infoMaxWidth
      });

      // Industry
      pdfDoc.setFontSize(10)
      pdfDoc.text('Branche', alignUnderAvatar, 120, {
        align: 'center',
      });
      pdfDoc.setFontSize(8)
      pdfDoc.text(industry.value, alignUnderAvatar, 126, {
        align: 'center',
        maxWidth: infoMaxWidth
      });

      // Organization Size
      pdfDoc.setFontSize(10)
      pdfDoc.text('Størrelsen af deres organisation', alignUnderAvatar, 152, {
        align: 'center',
      });
      pdfDoc.setFontSize(8)
      pdfDoc.text(sizeOfOrganization.value, alignUnderAvatar, 158, {
        align: 'center',
        maxWidth: infoMaxWidth
      });

      // Social Network
      pdfDoc.setFontSize(10)
      pdfDoc.text('Sociale netværk', alignUnderAvatar, 184, {
        align: 'center',
      });

      if (socialNetworks.value) {

        const imageSocilaNetworkData = await imgToBase64Async('/gfx/social-icons/social-networks.png')
        pdfDoc.addImage(imageSocilaNetworkData, 'PNG', 13, 190, 34, 20)

        for (const network of socialNetworks.value) {
          const socialIcon = network.name.toLowerCase()
          const iconWidth = 10
          const iconSpace = 2
          let iconStartPos = {x: startLeft, y: 190}

          switch (network.name) {
            case 'Facebook':
              iconStartPos = {x: alignUnderAvatar - 1.5 * iconWidth - iconSpace, y: 190}
              break;
            case 'Instagram':
              iconStartPos = {x: alignUnderAvatar - 0.5 * iconWidth, y: 190}
              break;
            case 'Twitter':
              iconStartPos = {x: alignUnderAvatar + 0.5 * iconWidth + iconSpace, y: 190}
              break;
            case 'LinkedIn':
              iconStartPos = {x: alignUnderAvatar - iconWidth - iconSpace, y: 200}
              break;
            case 'Pinterest':
              iconStartPos = {x: alignUnderAvatar + iconSpace, y: 200}
              break;
          }

          const imageData = await imgToBase64Async(`/gfx/social-icons/${socialIcon}.png`)
          pdfDoc.addImage(imageData, 'PNG', iconStartPos.x, iconStartPos.y, iconWidth, iconWidth)
        }
      }

      // Additional Info
      // Preferred Method of Communication
      pdfDoc.setFontSize(10)
      pdfDoc.text('Foretrukne måde at kommunikere på ', additionalInfoStartLeft, 36, {
        align: 'left',
      });
      pdfDoc.setFontSize(8)

      if(personaCommunicate.value) {
        let comMethodeText = "";
        let methodeIndex = 1

        for (let comMethod of personaCommunicate.value) {
          comMethodeText += comMethod.name
          if (methodeIndex < personaCommunicate.value.length) {
            comMethodeText += ', '
          } else {
            comMethodeText += '.'
          }
          methodeIndex++
        }

        pdfDoc.text(comMethodeText, additionalInfoStartLeft, 42, {
          align: 'left',
          maxWidth: additionalInfoMaxWidth
        });
      }

      // Tools They Need to Do Their Job
      pdfDoc.setFontSize(10)
      pdfDoc.text('Værktøjer de har brug for til at udføre deres job', additionalInfoStartLeft, 52, {
        align: 'left',
      });

      pdfDoc.setFontSize(8)
      if(personaTools.value){
        let toolText = "";
        let toolIndex = 1

        for(let tool of personaTools.value) {
          toolText += tool
          if(toolIndex < personaTools.value.length){
            toolText += ', '
          } else {
            toolText += '.'
          }
          toolIndex++
        }

        pdfDoc.text(toolText, additionalInfoStartLeft, 58, {
          align: 'left',
          maxWidth: additionalInfoMaxWidth
        });
      }

      // Job Responsibilities
      pdfDoc.setFontSize(10)
      pdfDoc.text('Jobansvar', additionalInfoStartLeft, 78, {
        align: 'left',
      });
      pdfDoc.setFontSize(8)
      pdfDoc.text(jobResponsibilities.value, additionalInfoStartLeft, 84, {
        align: 'left',
        maxWidth: additionalInfoMaxWidth
      });

      // Their Job Is Measured By
      pdfDoc.setFontSize(10)
      pdfDoc.text('Deres job bliver målt på', additionalInfoStartLeft, 100, {
        align: 'left',
      });
      pdfDoc.setFontSize(8)
      pdfDoc.text(jobMeasured.value, additionalInfoStartLeft, 106, {
        align: 'left',
        maxWidth: additionalInfoMaxWidth
      });

      // Reports To
      pdfDoc.setFontSize(10)
      pdfDoc.text('Rapporterer til', additionalInfoStartLeft, 122, {
        align: 'left',
      });
      pdfDoc.setFontSize(8)
      pdfDoc.text(whoReportTo.value, additionalInfoStartLeft, 128, {
        align: 'left',
        maxWidth: additionalInfoMaxWidth
      });

      // Goals or Objectives
      pdfDoc.setFontSize(10)
      pdfDoc.text('Mål eller fokus', additionalInfoStartLeft, 144, {
        align: 'left',
      });
      pdfDoc.setFontSize(8)
      pdfDoc.text(goalsOrObjectives.value, additionalInfoStartLeft, 150, {
        align: 'left',
        maxWidth: additionalInfoMaxWidth
      });

      // They Gain Information By
      pdfDoc.setFontSize(10)
      pdfDoc.text('Information/nyheder/viden til deres job', additionalInfoStartLeft, 166, {
        align: 'left',
      });
      pdfDoc.setFontSize(8)
      pdfDoc.text(howGainInformationForJob.value, additionalInfoStartLeft, 172, {
        align: 'left',
        maxWidth: additionalInfoMaxWidth
      });

      // Biggest Challenges
      pdfDoc.setFontSize(10)
      pdfDoc.text('Største udfordringer', additionalInfoStartLeft, 188, {
        align: 'left',
      });
      pdfDoc.setFontSize(8)

      if(personaBiggestChallenges.value) {
        let challengesText = "";
        let challengesIndex = 1

        for (let challenge of personaBiggestChallenges.value) {
          challengesText += challenge
          if (challengesIndex < personaBiggestChallenges.value.length) {
            challengesText += ', '
          } else {
            challengesText += '.'
          }
          challengesIndex++
        }

        pdfDoc.text(challengesText, additionalInfoStartLeft, 194, {
          align: 'left',
          maxWidth: additionalInfoMaxWidth
        });
      }

      // Save PDF file
      pdfDoc.save(`${personaName.value}.pdf`)

      /************************/
    }

    return{
      displayModal,
      firstName,
      lastName,
      company,
      email,
      phone,
      website,
      selectedJobFunction,
      jobFunctions,
      errorFirstName,
      errorLastName,
      errorCompany,
      errorEmail,
      errorPhone,
      errorWebsite,
      errorJobFunction,
      closeModal,
      createContact,
      submitLeadForm,
      errorHubspotForm,
      hubspotFormErrorMessage,
    }
  }
}
</script>

<style scoped>
.p-float-label{
  margin-top: 41px;
}

.form_dropdown{
  margin-top: 26px;
}

.form_dropdown .form_label{
  margin-bottom: 10px;
}

.form_dropdown .p-dropdown.p-component{
  width: 100%;
}

</style>