<template>
    <Card>
      <template #title>
        Foretrukne måde at kommunikere på
      </template>
      <template #content>
        <div class="communication_list persona_overview">
          <div class="">
            <SelectButton v-model="personaCommunicate" :options="communicateOptions" optionLabel="name" multiple>
              <template #option="slotProps">
                <div>
                  <i :class="slotProps.option.icon"></i>
                  <label>{{slotProps.option.name}}</label>
                </div>
              </template>
            </SelectButton>
          </div>
        </div>
      </template>
    </Card>
</template>

<script>

import {computed} from "vue";
import store from "@/store/store";

export default {
  name: "MethodOfCommunication",
  setup(){

    const communicateOptions = computed( () => store.state.listCommunicate )

    let personaCommunicate = computed({
      get: () => store.state.personaCommunicate,
      set: val => {
        store.dispatch(('setPersonaCommunicate'), {communicate: val})
      }
    })

    return{
      communicateOptions,
      personaCommunicate
    }
  }
}
</script>

<style scoped>

</style>