<template>
  <Card class="persona_main_info">
    <template #header>
      <img class="persona_avatar" alt="persona" :src="`/gfx/avatars/${avatarImg}.svg`" >
    </template>
    <template #content>
      <div>
        <h3>Navn</h3>
        <InputText type="text" v-model="personaName" placeholder="Indtast teks her" />
      </div>
      <Divider />
      <div>
        <h3>Jobtitel</h3>
        <InputText type="text" v-model="jobTitle" placeholder="Indtast teks her" />
      </div>
      <Divider />
      <div>
        <h3>Alder</h3>
        <InputText type="text" v-model="age" placeholder="Indtast teks her" />
      </div>
      <Divider />
      <div>
        <h3>Højeste uddannelse</h3>
        <InputText type="text" v-model="levelOfSchool" placeholder="Indtast teks her" />
      </div>
      <div>
        <h3>Sociale netværk</h3>
        <div class="social_network_list persona_overview">
          <SelectButton v-model="personaSocialNetwork" :options="socialNetworkOptions" optionLabel="name" multiple>
            <template #option="slotProps">
              <div>
                <i :class="slotProps.option.icon"></i>
                <label>{{slotProps.option.name}}</label>
              </div>
            </template>
          </SelectButton>
        </div>
      </div>
      <Divider />
      <div>
        <h3>Branche</h3>
        <InputText type="text" v-model="selectedIndustry" placeholder="Indtast teks her" />
      </div>
      <div>
        <h3>Størrelsen af deres organisation</h3>
        <InputText type="text" v-model="sizeOfOrganization" placeholder="Indtast teks her" />
      </div>
    </template>
  </Card>
</template>

<script>
import {computed} from "vue";
import store from "@/store/store";

export default {
  name: "PersonaMainInfo",
  setup(){
    const avatarImg = computed( () => {
      if(store.state.avatar){
        return store.state.avatar
      } else {
        return 'avatar-0'
      }
    })

    const personaName = computed({
      get: () => store.state.personaName,
      set: val => {
        store.dispatch(('setPersonaName'), {name: val})
      }
    });

    const jobTitle = computed({
      get: () => store.state.jobTitle,
      set: val => {
        store.dispatch(('setJobTitle'), {title: val})
      }
    })

    const age = computed({
      get: () => store.state.personaAge,
      set: val => {
        store.dispatch(('setPersonaAge'), {age: val})
      }
    })

    const levelOfSchool = computed({
      get: () => store.state.levelOfSchool,
      set: val => {
        store.dispatch(('setLevelOfSchool'), {level: val})
      }
    })

    const selectedIndustry = computed({
      get: () => store.state.industry,
      set: val => {
        store.dispatch(('setIndustry'), {industry: val})
      }
    })

    const sizeOfOrganization = computed({
      get: () => store.state.sizeOfOrganization,
      set: val => {
        store.dispatch(('setSizeOfOrganization'), {size: val})
      }
    })

    const socialNetworkOptions = computed( () => store.state.socialNetworkOptions )
    let personaSocialNetwork = computed({
      get: () => store.state.personaSocialNetwork,
      set: val => {
        store.dispatch(('setPersonaSocialNetwork'), {social: val})
      }
    })

    return{
      avatarImg,
      personaName,
      jobTitle,
      age,
      levelOfSchool,
      selectedIndustry,
      sizeOfOrganization,
      socialNetworkOptions,
      personaSocialNetwork
    }

  }
}
</script>

<style scoped>
.persona_main_info{
  width: 100%;
  padding-top: 1rem;
}

.persona_main_info .persona_avatar{
  display: block;
  max-width: 200px;
  margin: 0px auto;
}

</style>