<template>
  <Card>
    <template #title>
      Største udfordringer
    </template>
    <template #content>
      <div class="cb_list">
        <div v-for="challenge of listBiggestChallenges" :key="challenge.id" class="p-field-checkbox">
          <Checkbox :id="challenge.id" name="challenges" :value="challenge.val" v-model="personaBiggestChallenges" />
          <label :for="challenge.id">{{ challenge.val }}</label>
        </div>
      </div>
      <div>
        <span class="p-float-label">
          <InputText id="additionalChallenge" type="text" @keydown="addAdditionalChallange" v-model="additionalChallenge"/>
          <label for="additionalChallenge">Du kan tilføje en her og trykke på enter.</label>
        </span>
      </div>
    </template>
  </Card>
</template>

<script>
import {computed, ref} from "vue";
import store from "@/store/store";

export default {
  name: "BiggestChallenges",
  setup() {
    const listBiggestChallenges = computed( () => { return store.state.listBiggestChallenges})

    const personaBiggestChallenges = computed({
      get: () => store.state.personaBiggestChallenges,
      set: val => {
        store.dispatch(('setPersonaBiggestChallenges'), {biggestChallenges: val})
      }
    })

    let additionalChallenge = ref("");

    function addAdditionalChallange(e) {
      if(e.keyCode === 13) {
        const challangeId = additionalChallenge.value.replace(' ', '-')
        const challange = {id: challangeId, val: additionalChallenge.value}

        let challangeFound = listBiggestChallenges.value.find( challange => challange.id === challangeId )
        if(!challangeFound) {
          store.dispatch(('updateListBiggestChallenges'), {additionalChallenge: challange})
          store.dispatch(('updatePersonaBiggestChallenges'), {challenge: challange.val})
          additionalChallenge.value = "";
        }
      }
    }

    return{
      listBiggestChallenges,
      personaBiggestChallenges,
      additionalChallenge,
      addAdditionalChallange
    }
  }
}
</script>

<style scoped>
.p-field-checkbox {
  flex: 0 0 100%;
}
</style>