<template>
  <hubspot-lead-form></hubspot-lead-form>
  <div>
    <div>
      <h1>Lav min Persona-oversigt</h1>
    </div>
    <div class="overview_tools">
      <Button label="Download/Export" @click="openHubspotForm" />
    </div>
  </div>
  <div ref="personaOverview">
    <div class="persona_overview_info">
      <div class="main_info">
        <persona-main-info></persona-main-info>
      </div>
      <div class="additional_info">
        <div class="info_card">
          <method-of-communication></method-of-communication>
        </div>
        <div class="info_card">
          <tools-need-do-job></tools-need-do-job>
        </div>
        <div class="info_card">
          <job-responsibilities></job-responsibilities>
        </div>
        <div class="info_card">
          <job-measured-by></job-measured-by>
        </div>
        <div class="info_card">
          <reports-to></reports-to>
        </div>
        <div class="info_card">
          <goals-or-objects></goals-or-objects>
        </div>
        <div class="info_card">
          <gain-information-by></gain-information-by>
        </div>
        <div class="info_card">
          <biggest-challenges></biggest-challenges>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue"
import store from "@/store/store";

import PersonaMainInfo from "@/components/personaOverviewCards/PersonaMainInfo";
import MethodOfCommunication from "@/components/personaOverviewCards/MethodOfCommunication";
import ToolsNeedDoJob from "@/components/personaOverviewCards/ToolsNeedDoJob";
import JobResponsibilities from "@/components/personaOverviewCards/JobResponsibilities";
import JobMeasuredBy from "@/components/personaOverviewCards/JobMeasuredBy";
import ReportsTo from "@/components/personaOverviewCards/ReportsTo";
import GoalsOrObjects from "@/components/personaOverviewCards/GoalsOrObjectives";
import GainInformationBy from "@/components/personaOverviewCards/GainInformationBy";
import BiggestChallenges from "@/components/personaOverviewCards/BiggestChallenges";
import HubspotLeadForm from "@/components/HubspotLeadForm";

export default {
name: "PersonaOverview",
  components: {
    HubspotLeadForm,
    BiggestChallenges,
    GainInformationBy,
    GoalsOrObjects,
    ReportsTo,
    JobMeasuredBy,
    JobResponsibilities,
    ToolsNeedDoJob,
    MethodOfCommunication,
    PersonaMainInfo},
  setup() {
    const personaOverview = ref(null)

    function openHubspotForm() {
      store.dispatch('setDisplayHubspotForm', {displayModal: true})
    }

    return{
      personaOverview,
      openHubspotForm
    }
  }
}
</script>

<style scoped>
.persona_overview_info{
  display: flex;
  align-items: flex-start;
}

.persona_overview_info .main_info{
  flex: 0 0 25%;
  max-width: 25%;
  padding-right: 10px;
}

.persona_overview_info .additional_info{
  flex: 0 0 75%;
  max-width: 75%;
  display: flex;
  flex-wrap: wrap;
}

.persona_overview_info .additional_info .info_card{
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0px 10px 10px 10px;
}

.persona_overview_info .additional_info .info_card .p-card{
  height: 100%;
}

.overview_tools{
  text-align: right;
  padding-right: 10px;
  margin-bottom: 10px;
}

</style>